import { useState } from 'react'
import { Screens } from '../model'
import { AppButton } from '../components/AppButton'
import TelegramIcon from '../assets/svgs/Telegram.svg'
import { LoginScreenTgLink } from '../constants'
import { onLogin } from '../api'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { useGetSkinByLocation } from '../utils/common'
import { ToggleGameButton } from '../components/ToggleGameButton'

//латинские буквы и цифры
const regex = /^[A-Za-z0-9]+$/

interface Props {
  setScreen: (screen: Screens) => void
  setPrevScreen: (screen: Screens) => void
  setIsLoggedIn: (b: boolean) => void
}

export const LoginScreen: React.FC<Props> = ({
  setScreen,
  setPrevScreen,
  setIsLoggedIn,
}) => {
  const [userLogin, setUserLogin] = useState('')
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginEmpty, setLoginEmpty] = useState(false)
  const [validationFailed, setValidationFailed] = useState(false)

  const navigate = useNavigate()

  const [skin, skinToToggle] = useGetSkinByLocation()

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const login = event.target.value
    setUserLogin(login)
    if (!regex.test(login) && login.length > 0) {
      setValidationFailed(true)
    }
    if (validationFailed && (regex.test(login) || login.length === 0)) {
      setValidationFailed(false)
    }
    if (loginFailed) {
      setLoginFailed(false)
    }
    if (loginEmpty) {
      setLoginEmpty(false)
    }
  }

  const onLoginClick = async () => {
    if (userLogin) {
      try {
        const loginInfo = await onLogin(userLogin)
        if (loginInfo.auth) {
          if (loginInfo.ban) {
            navigate(`/error${skin.url}`)
          } else {
            setLoginFailed(false)
            setIsLoggedIn(true)
            localStorage.setItem('oracle_id', userLogin)
            setPrevScreen(Screens.login)
            setScreen(Screens.loading)
          }
        } else {
          setLoginFailed(true)
        }
      } catch (e: any) {
        console.log(e)
        setLoginFailed(true) // show incorrect ID-message on every error
      }
    } else {
      setLoginEmpty(true)
    }
  }

  return (
    <div className={'column login-screen'}>
      <ToggleGameButton skinToToggle={skinToToggle}/>
      <div>
        <p className="text__header margin-bottom-10">Ваш ID</p>
        {!loginFailed ? (
          <p className="text__regular">
            Введіть ID вашого акаунта
            <br />
            для доступу до AІ
          </p>
        ) : (
          <p className="text__regular">
            Щоб AІ зміг синрохнізуватися з вашим
            <br />
            акаунтом, необхідно зробити хоча б
            <br />
            {`1 ставку в грі ${skin.label}`}
          </p>
        )}
      </div>
      <div className="column body">
        <div>
          <input
            className={clsx(
              'oracle__input',
              (loginFailed || validationFailed) && 'error',
            )}
            placeholder="Ваш ID"
            value={userLogin}
            maxLength={30} // change max login length
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange(event)
            }}
          />
          {loginFailed && <p className="text__error">Акаунт не знайдено</p>}
          {loginEmpty && (
            <p className="text__error empty">ID не може бути порожнім</p>
          )}
          {validationFailed && (
            <p className="text__error">
              ID може містити лише цифри та латинські літери
            </p>
          )}
        </div>
        <AppButton
          label="Увійти"
          onClick={onLoginClick}
          extraClass={skin.extraClass}
          disabled={validationFailed}
        />
        <p className="text__small aside-lines">Є питання? Ми допоможемо</p>
        <AppButton
          label="Інструкція"
          onClick={() => window.open(LoginScreenTgLink)}
          icon={<img src={TelegramIcon} alt="" />}
          extraClass="telegram"
        />
      </div>
    </div>
  )
}
