import { unpackMultiple } from 'msgpackr'

import { EventEmitter } from './EventEmitter'

const close = 'close'
const open = 'open'
const message = 'message'
const error = 'error'

interface OptionsType {
  autoConnect?: boolean
  autoReconnect?: boolean
}

const defaultOptions: OptionsType = {
  autoConnect: true,
  autoReconnect: false,
}

const RECONNECT_TIMEOUT = 1000

export class WsNotifier extends EventEmitter {
  private readonly url: string

  private readonly isMessagePack: boolean

  private options: OptionsType

  private reconnectTimeout: number = 0

  socket: WebSocket | undefined

  constructor(
    url: string,
    options?: OptionsType,
    isMessagePack: boolean = true
  ) {
    super()
    this.url = url
    this.isMessagePack = isMessagePack
    this.options = { ...defaultOptions, ...options }

    if (this.options.autoReconnect) {
      this.reconnectTimeout = 0

      this.on(close, () => {
        if (this.options.autoReconnect) {
          setTimeout(this.connect.bind(this), this.reconnectTimeout)
          this.reconnectTimeout = RECONNECT_TIMEOUT
        }
      })

      this.on(open, () => {
        this.reconnectTimeout = 0
      })
    }

    this.on(error, () => {
      this.socket?.close()
    })

    if (this.options.autoConnect) {
      this.connect()
    }
  }

  connect(): void {
    this.socket = new WebSocket(this.url)
    this.socket.binaryType = 'blob'

    this.socket.onopen = (event) => this.emit(open, event)
    this.socket.onclose = (event) => this.emit(close, event)
    this.socket.onerror = (event) => this.emit(error, event)

    this.socket.onmessage = async (event) => {
      let { data } = event

      if (typeof data !== 'string') {
        if (this.isMessagePack) {
          const buffer = await data.arrayBuffer()
          data = await new Uint8Array(buffer)
        } else {
          data = await new Response(data).text()
        }
      }

      try {
        const msg = this.isMessagePack ? unpackMultiple(data) : JSON.parse(data)

        if (Array.isArray(msg)) {
          msg.forEach((obj) => {
            if (this.isMessagePack && typeof obj === 'object' && obj !== null) {
              this.emit(message, obj)
            }

            if (!this.isMessagePack) {
              this.emit(message, obj)
            }
          })

          return
        }

        this.emit(message, msg)
      } catch (e) {
        this.emit(message, data)
      }
    }
  }

  close(force?: boolean): void {
    if (force) {
      const prevReconnectValue = this.options.autoReconnect

      this.options.autoReconnect = false

      this.once(close, () => {
        this.options.autoReconnect = prevReconnectValue
      })
    }

    this.socket?.close()
  }
}
