import { howToUseModal } from '../../../../constants'
import howToUsePreview from '../../../../assets/img/howToUsePreview.png'
import { RefObject, useState } from 'react'
import ReactPlayer from 'react-player/youtube'

export const MetaCrashHowToUseContent = (contentRef: RefObject<HTMLDivElement>) => {

const [YouTubePlaying, setYouTubePlaying] = useState(false)

return (
    <div ref={contentRef} className='content'>
    <div className='content-title-wrapper'>
      <h3 className='title'>Як працює штучний інтелект “Jet Winner”</h3>
    </div>
    <div className='text'>
      Як і в усіх програмах подібного типу “Jet Winner” навчений 
      за допомогою технології - Big Data. Це спосіб аналізу та обробки 
      дуже великих об'ємів дати, як структурованих, так і неструктурованих. 
      Також, софт запрограмований на навчання за допомогою комунікації з 
      людиною - фідбеку від гравця. Це все зроблено для отримання нових 
      знань та удосконалення наявних.
    </div>
    <div className='video' style={{ marginBottom: 0 }}>
      <ReactPlayer
        url={howToUseModal.content.videoURL}
        width='100%'
        height='100%'
        controls
        playing={YouTubePlaying}
        onClickPreview={() => {
          setYouTubePlaying(true)
        }}
        light={
          <img
            src={howToUsePreview}
            alt='thumbnail'
            className='preview'
          />
        }
      />
    </div>
    <div className='video-details'>
      <p>{howToUseModal.content.videoDetail}</p>
    </div>
    <div className='text'>
      Тож, АІ постійно самовдосконалюється і видає коефіцієнт 
      гри, який з великою ймовірністю принесе найбільший виграш.
      <br />
      <br />
      Це працює так: чим більше гравців користуються софтом та, 
      чим більше фідбеку дають - тим точніші будуть результати. 
      Адже це покладено в основу самої системи.
      <br />
      Більше дати, комунікації та фідбеку = більші виграші, точніші коефіцієнти. 
    </div>
    <br />
    <div className='content-title-wrapper'>
      <h3 className='title'>
      Алгоритм функціонування “Jet Winner” та покращення результатів
      </h3>
    </div>
    <div className='text'>
      Як я вже сказав, щоб отримувати більш точну інформацію на кожну наступну 
      гру, гравцям необхідно надавати фідбек по отриманим коефіцієнтам. Таким 
      чином АІ буде збирати більше даних, стосовно гри, та вираховувати коректні 
      результати (ставки).
      <br /> <br />
      Для цього потрібно буде обрати один з варіантів відповіді на коефіцієнт: 
      <ul className='text'>
        <li>правильно</li>
        <li>неправильно</li>
      </ul>
      І завдяки збору великого обсягу даних та машинному аналізу відгуків від 
      користувачів АІ самостійно покращує точність коефіцієнтів для наступної гри.
    </div>
    <br />
    <div className='text bold'>
    Одразу кажу, що я нікому НЕ гарантую 100%. Софт дає шанс виграти приблизно 40%-70%.
    </div>
  </div>
    )
}