import clsx from 'clsx'

interface Props {
  logo: any
  small?: boolean
}

export const SpinningLogo: React.FC<Props> = ({ logo, small }) => {

return (
  <div className={clsx('logo-wrapper', small ? 'small' : 'big')}>
    <div className={clsx('logo spinning', small && 'small')}>
        <img src={logo} alt='' />
    </div>
    {!small && (<div className='logo-light' />)}
  </div>
)
}
