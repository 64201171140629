import axios, { AxiosResponse } from "axios";
import { LoginParams } from "../model";

const instance = axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_BACKEND ?? '',
  withCredentials: false
})

const login = (payload: { login: string }): Promise<AxiosResponse<LoginParams>> => {
  return instance.post('/login', payload)
}

export const onLogin = async (userLogin: string): Promise<LoginParams> => {
  return (await login({ login: userLogin })).data
}

export const rate = async ( game: string, login?: string) => {
  if (login) {
    return instance.get(`/rate?login=${login}&gameName=${game}`)
  }
  return instance.get(`/rate?gameName=${game}`)
}