import { SkinType } from "../model"
import { skins } from "./constants"
import { useLocation } from "react-router-dom"
import { useEffect, useRef, useState } from "react"

export const useGetSkinByLocation = () => {
    let currentSkin
    let skinToToggle

    const location = useLocation()

    switch (location.pathname.split('/').at(-1)) {
        case (skins.metacrash.url): {
            currentSkin = skins.metacrash
            skinToToggle = skins.airjet
            break
        }
        case (skins.airjet.url):
        default: {
            currentSkin = skins.airjet
            skinToToggle = skins.metacrash
            break
        }
    }
    return [currentSkin, skinToToggle]
}

export const useCheckConnectionIsLost = (setConnectionIsLost: (a: boolean) => void) => {
    
    const updateConnectionState = () => setConnectionIsLost(!navigator.onLine)

    useEffect (() => {
    window.addEventListener('online',  updateConnectionState)
    window.addEventListener('offline', updateConnectionState)
    return () => {
      window.removeEventListener('online',  updateConnectionState)
      window.removeEventListener('offline', updateConnectionState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

const MAX = 8000
const MIN = 4000
const REFRESH_TIME = 10000
const RANGE = 0.01 // 0.01 = 1%
const CHANCE = 0.5 // 0.5 = 50%

interface Map {
    [key: string]: number
  }

const initOnlineUsers: Map = {
    airjet: 0,
    metacrash: 0
}

export const useGetRandomOnlineUsers = (skin: SkinType) => {
    const initialOnlineUsersRef = useRef<Map>(initOnlineUsers)
    const [onlineUsers, setOnlineUsers] = useState<number>(0)
    const intervalRef = useRef<any>(null)

    const isAirJet = skin.url === skins.airjet.url
    const isMetaCrash = skin.url === skins.metacrash.url
    
    const setupInterval = (skin: SkinType): void => {
        intervalRef.current = setInterval(() => {
          const operation = Math.random() < CHANCE
          const range = initialOnlineUsersRef.current[skin.url] * RANGE

          setOnlineUsers((prev) => {
            let result = prev
            if (operation) {
              result = Math.floor(prev - range)
              if (result < MIN) {
                result = MIN
              }

            } else {
                result = Math.floor(prev + range)
                if (result > MAX) {
                result = MAX
                }
            }
            
            initialOnlineUsersRef.current[skin.url] = result
            return result
          })
        }, REFRESH_TIME)
      }
    
      useEffect(() => {
        if ((!initialOnlineUsersRef.current.airjet && isAirJet) || 
                (!initialOnlineUsersRef.current.metacrash && isMetaCrash)) {
        
            const users = Math.floor(Math.random() * (MAX + 1 - MIN) + MIN)
            setOnlineUsers(users)

            initialOnlineUsersRef.current[skin.url] = users
        } else {
            setOnlineUsers(initialOnlineUsersRef.current[skin.url])
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [skin])
    
      useEffect(() => {
        if (!intervalRef.current) {
          setupInterval(skin)
        } else {
          clearInterval(intervalRef.current)
          setupInterval(skin)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [skin])

    return onlineUsers
}