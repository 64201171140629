interface Props {
  completed: number
  containerClass: string
  fillerClass: string
}

export const ProgressBar: React.FC<Props> = (props) => {
  const { completed, containerClass, fillerClass } = props

  return (
    <div className={containerClass}>
      <div className={fillerClass} style={{ width: `${completed}%` }} />
    </div>
  )
}

export default ProgressBar
