import clsx from 'clsx'
import { useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import closeButtonSVG from '../../../assets/svgs/closeButton.svg'
import { useGetSkinByLocation } from '../../../utils/common'

interface Props {
  open: boolean
  onClose: () => void
  className?: string
}

export const HowToUseModal: React.FC<Props> = ({
  open,
  onClose,
  className,
}) => {

  const contentRef = useRef<HTMLDivElement>(null)
  const handlers = useSwipeable({
    onSwipedDown: () => {
        if (contentRef.current?.scrollTop === 0) {
          onClose()
        }
      },
  })

  const [skin] = useGetSkinByLocation()

  return (
    <div className={clsx(['oracle__modal', [open && 'open'], className])}>
      <div {...handlers} className='wrapper'>
        <div className='header'>
          <button type='button' onClick={onClose} className='close-button'>
            Закрити
          </button>
          <div className='header-text'>{skin.howToUseTitle}</div>
          <button type='button' className='close-button-icon' onClick={onClose}>
            <img src={closeButtonSVG} alt='closeButton' />
          </button>
        </div>
        {skin.howToUseContent(contentRef)}
      </div>
    </div>
  )
}

HowToUseModal.defaultProps = {
  className: '',
}
