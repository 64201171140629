interface Props {
  logo?: any
}

export const Logo: React.FC<Props> = ({ logo }) => (
  <div className='logo-wrapper big'>
    <div className='logo' >
      <img src={logo} alt='' />
    </div>
  <div className='logo-light' />
  </div>
)
