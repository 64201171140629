import React from "react";

interface Props{
  className?: string
}

export const ThumbsUp:React.FC<Props> = ({className}) => {
return (
    <svg className={className} width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5399 5.33299C15.3725 5.33299 16.1453 5.91935 16.2394 6.74665C16.2941 7.22812 16.1333 7.67372 15.8417 7.99949C16.6355 8.88611 16.2145 10.2982 15.0709 10.6097C15.1433 10.7926 15.1834 10.9915 15.1834 11.1993C15.1834 11.9357 14.683 12.5581 14.0043 12.743C14.4498 13.8678 13.5545 14.9324 12.405 14.9324H8.91647C7.05908 14.9324 5.78774 14.5019 4.78264 14.1615C4.69217 14.1309 4.60385 14.101 4.51746 14.0722V6.07009C4.58934 6.03416 4.65943 5.99958 4.72801 5.96573C5.31138 5.67782 5.78599 5.44359 6.33144 4.89814C7.23708 3.99247 7.71727 1.76653 7.71727 0.533314C7.71727 0.238519 7.95583 0 8.25059 0H8.69065C9.90074 0 10.9028 0.969687 10.9168 2.17974C10.9252 2.91076 10.7355 4.70029 10.3822 5.33299H14.5399Z" fill="currentColor"/>
      <path d="M0.783313 5.32985H2.91653C3.21108 5.32985 3.44981 5.56862 3.44981 5.86313V14.3959C3.44981 14.6904 3.21101 14.9292 2.9165 14.9292H0.783313C0.488767 14.9292 0.25 14.6904 0.25 14.3959V5.86316C0.25 5.56862 0.488802 5.32985 0.783313 5.32985Z" fill="currentColor"/>
    </svg>

)
}

ThumbsUp.defaultProps = {
  className: ''
}