import { SkinType } from "../model"
import { AppButton } from "./AppButton"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"

interface Props {
    skinToToggle: SkinType
  }
  
  export const ToggleGameButton: React.FC<Props> = ({
    skinToToggle,
  }) => {

    const navigate = useNavigate()
  
  return (
    <div className={clsx('oracle__toggle-wrapper', skinToToggle.extraClass)}>
      <p>Виберіть гру:</p>
      <AppButton 
      onClick={() => {
        navigate(`/${skinToToggle.url}`)
      }}
      label={skinToToggle.label}
      icon={<img src={skinToToggle.icon} alt='' className='oracle__toggle-icon' />}
      extraClass={clsx('toggle-game', skinToToggle.extraClass)}
      />
  </div>
  )
}