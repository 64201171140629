import './style.scss';

import OraclePage from './pages/OraclePage';
import ErrorPage from './pages/ErrorPage';
import RatePage from './pages/RatePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { skins } from './utils/constants';

function App() {
  return (
      <Router>
        <div>
          <Routes>
            {['/', skins.airjet.url, skins.metacrash.url].map(
              path => <Route path={path} element={<OraclePage />} key={path} />
              )
            }
            {[`/error/${skins.airjet.url}`, `/error/${skins.metacrash.url}`].map(
              path => <Route path={path} element={<ErrorPage />} key={path} />
              )
            }
            {[`/get-current-rate/${skins.airjet.url}`, `/get-current-rate/${skins.metacrash.url}`].map(
              path => <Route path={path} element={<RatePage />} key={path} />
              )
            }
          </Routes>
        </div>
      </Router>
  )
}

export default App;
