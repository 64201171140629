const fieldsMap = {
  g: 'autocashout_settings',
  k: 'bet_size',
  w: 'bet_size_usd',
  j: 'bets',
  x: 'coefficient',
  y: 'coefficient_index',
  b: 'currency',
  a: 'currency_settings',
  s: 'current_time',
  d: 'draw',
  p: 'event_id',
  e: 'event_type',
  o: 'index',
  f: 'meta',
  u: 'middle_coefficient',
  c: 'next_coefficient',
  l: 'next_server_seed',
  i: 'next_state_time',
  n: 'outcome',
  v: 'prize_size',
  m: 'round_id',
  h: 'slot_index',
  q: 'state',
  r: 'thanus_user_id',
  t: 'total_bet_count',
  z: 'name',
  A: 'user_seeds',
  B: 'server_seed',
  C: 'numbers',
  D: 'short_hash',
  E: 'number',
  F: 'decimal',
  G: 'min_bet_value',
  H: 'max_bet_value',
  J: 'min_value',
  L: 'max_value',
  K: 'hash',
  M: 'seed',
  N: 'voucher_id',
  Q: 'user_id',
  P: 'type',
  R: 'size',
  S: 'size_usd',
}

const eventTypeMap = {
  b: 'bet',
  f: 'cancel_bet',
  a: 'cancel_middle_bet',
  c: 'cash_out',
  j: 'change_coefficient',
  i: 'change_state',
  g: 'current_top_bets',
  m: 'end_game',
  d: 'kill_switch',
  h: 'middle_bet',
  l: 'middle_cash_out',
  e: 'start_game',
  k: 'stop_coefficient',
}

const gameStateMap = {
  a: 'betting',
  b: 'flying',
  c: 'waiting',
  d: 'ending',
}

const betTypeMap = {
  a: 'regular',
  b: 'middle',
}

const isObject = (value: unknown): boolean =>
  typeof value === 'object' && !Array.isArray(value) && value !== null

const inFactIsFloatRegExpr = new RegExp(
  `^(${[
    'number',
    'coefficient',
    'prize_size',
    'size(_usd)?',
    'bet_size(_usd)?',
    '(min|max)_value',
    '(min|max)_bet_value',
    '(min|max|next|middle|stop)_coefficient',
  ].join('|')})$`
)

const decodeEntity = (entity: any, isNeedToBeFloated = false): any => {
  const isArrayType = Array.isArray(entity)
  const isObjectType = isObject(entity)
  const isNumericType = typeof entity === 'number' && !Number.isNaN(entity)

  if (isArrayType) {
    return entity.map((item) => decodeEntity(item, isNeedToBeFloated))
  }

  if (isObjectType) {
    const res = {}

    // eslint-disable-next-line
    for (const key in entity) {
      // @ts-expect-error
      const keyDecoded = fieldsMap[key] || key
      const hasToBeFloat = inFactIsFloatRegExpr.test(keyDecoded)
      const value = entity[key]

      // @ts-expect-error
      res[keyDecoded] = decodeEntity(value, hasToBeFloat)
    }

    return res
  }

  if (isNumericType && isNeedToBeFloated) {
    return entity / 100
  }

  return entity
}

export const decodeEvent = (e: unknown): unknown => {
  const event = decodeEntity(e)

  return {
    ...event,
    // @ts-expect-error
    ...(event.event_type ? { event_type: eventTypeMap[event.event_type] } : {}),
    // @ts-expect-error
    ...(event.state ? { state: gameStateMap[event.state] } : {}),
    // @ts-expect-error
    ...(event.type ? { type: betTypeMap[event.type] } : {}),
  }
}
