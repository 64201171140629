import { howToUseModal } from '../../../../constants'
import howToUsePreview from '../../../../assets/img/howToUsePreview.png'
import { RefObject, useState } from 'react'
import ReactPlayer from 'react-player/youtube'

export const AirJetHowToUseContent = (contentRef: RefObject<HTMLDivElement>) => {

const [YouTubePlaying, setYouTubePlaying] = useState(false)

return (
    <div ref={contentRef} className='content'>
    <div className='content-title-wrapper'>
      <h3 className='title'>Особливості штучного інтелекту (АІ) “Jet Winner”</h3>
    </div>
    <div className='text'>
      Для гри “AirJet” спеціально був розроблений застосунок “Jet Winner”, 
      який має можливість передбачити коефіцієнт до якого буде тривати гра. 
      Крім цього, штучний інтелект здатен прогнозувати коефіцієнти на наступні раунди.
    </div>
    <div className='video' style={{ marginBottom: 0 }}>
      <ReactPlayer
        url={howToUseModal.content.videoURL}
        width='100%'
        height='100%'
        controls
        playing={YouTubePlaying}
        onClickPreview={() => {
          setYouTubePlaying(true)
        }}
        light={
          <img
            src={howToUsePreview}
            alt='thumbnail'
            className='preview'
          />
        }
      />
    </div>
    <div className='video-details'>
      <p>{howToUseModal.content.videoDetail}</p>
    </div>
    <div className='text'>
        Як це працює? АІ такого типу запрограмовані на постійне навчання 
        через взаємодію з людьми та великими обсягами дати. Таким чином, 
        кожен раз аналізуючи вже наявні схеми гри, які є в інтернеті та 
        відгуки гравців, АІ постійно самовдосконалюється і видає коефіцієнт 
        гри, який з великою ймовірністю принесе найбільший виграш.
        <br />
        <br />
        Це працює так, що чим більше гравців користуються софтом та, чим більше 
        фідбеку вони дають - тим точніші будуть результати. Адже це покладено в 
        основу самої системи.
        <br />
        Більше дати, комунікації = більші виграші, точніші коефіцієнти. 
    </div>
    <br />
    <div className='content-title-wrapper'>
      <h3 className='title'>
        Алгоритм функціонування АІ та його покращення
      </h3>
    </div>
    <div className='text'>
      Після кожного раунду гри, у котрій використовувався коефіцієнт 
      наданий софтом, користувач може залишити зворотний зв'язок (кнопки 
      правильно та неправильно), відповідно до виграшу.
      <br /> <br />
      Це робиться буквально одним кліком, який буде визначати коректність 
      функціонування АІ та точність передбачення.
      <br /> <br />
      Таким чином, завдяки збору великого обсягу даних та машинному аналізу 
      відгуків від користувачів АІ самостійно покращує точність коефіцієнтів 
      для наступної гри. 
    </div>
    <br />
    <div className='text bold'>
      Одразу кажу, що я нікому НЕ гарантую 100%. Софт дає шанс виграти приблизно 40%-70%. 
    </div>
  </div>
    )
}