import { Logo } from "../components/Logo";
import React from "react";
import TelegramIcon from "../assets/svgs/Telegram.svg";
import { AppButton } from "../components/AppButton";
import { errorPage, ErrorPageTgLink } from "../constants";
import { useGetSkinByLocation } from "../utils/common";
import clsx from "clsx";

const ErrorPage: React.FC = () => {

const [ skin ] = useGetSkinByLocation()

    return (
        <div className={clsx('oracle__wrapper', skin.extraClass)}>
            <div className={'oracle error-screen'}>
                <Logo logo={skin.logo} />
                <div className={'description-block'}>
                    <h3 className={'title'}>
                        {errorPage.title}
                    </h3>
                    <p className={'subtitle'}>{errorPage.subtitle}</p>
                </div>
                <div className={'info-block'}>
                    <p style={{marginBottom: '15px'}}>{`${errorPage.info.p1} ${skin.label}.`}
                    </p>
                    <p>{errorPage.info.p2}
                    </p>
                </div>
                <div className={'restore-access-block'}>
                    <AppButton
                        label={errorPage.buttonLabel}
                        onClick={() => window.open(ErrorPageTgLink)}
                        icon={<img src={TelegramIcon} alt='telegram-access' />}
                        extraClass='telegram'
                    />
                </div>
            </div>
        </div>
    )
}

export default ErrorPage